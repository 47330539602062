.actionBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.5em 0em;
}

.tableHeaderRow {
  border-color: #5B7FF1;
}

.tableHeaderCell {
  color: black;
  font-weight: bold;
  padding: 1em;
  cursor: pointer;
}

.tableBody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

.tableCell {
  padding: 0.75em;
}

.tableRow:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
  cursor: pointer;
}

.tableFooter td {
  border-bottom: 0 !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15em;
  min-height: 15em;
}

.emptyList {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15em;
  min-height: 15em;
  font-size: 18px;
}