.titre {
    display: flex;
    font-size: 18px;
    font-weight: bold;
}

.mainButton {
    border-radius: 6px !important;
    border: 1px solid grey !important;
    color: black !important;
    padding: 0.5em 1em !important;
}