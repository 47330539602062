.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--clr-neutral-900);
    border-radius: 6px;
    padding: 11px 15px;

    &[data-status='error'] {
        background-color: rgb(250, 218, 219);
    }
}
