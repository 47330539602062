:root {
    --clr-neutral-0: #38404c;
    --clr-neutral-100: #797676;
    --clr-neutral-800: #bccbdf;
    --clr-neutral-900: #f4f6fa;
    --clr-primary-100: #3e69b9;
    --clr-primary-200: #5189f2;
    --clr-success: #00c853;
    --clr-error: #ff0000;
}

#social-networks-root {
    color: var(--clr-neutral-100);
    font-size: 1rem;
}

h1,
h2,
h3 {
    color: var(--clr-neutral-0);
    font-weight: 500;
    margin-top: 0;
}

h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.3em;
}

h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
}

h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
}

p {
    margin: 0;
}

button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

b {
    color: var(--clr-neutral-0);
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 1rem;
    font-weight: 600;
    height: 3.5em;
    border: none;
    padding: 0.5em 1em;
    border-radius: 6px;
}

.btn-primary,
.btn-primary:link,
.btn-primary:visited,
.btn-primary:active {
    background-color: var(--clr-primary-200);

    color: white;

    &:hover {
        background-color: var(--clr-primary-100);
    }
}

.btn-outline {
    background: none;
    color: var(--clr-primary-200);
    border: 1px solid var(--clr-primary-200);

    &:hover,
    &:focus,
    &:active {
        background-color: var(--clr-primary-200);
        color: white;
    }
}

time {
    cursor: pointer;
}
