.providerToggle {
    background-color: var(--clr-neutral-800);
    border-radius: 6px;
    padding: 8px 15px;
    outline: none;
    text-transform: capitalize;
    font-weight: 500;

    &[data-active='true'] {
        background-color: var(--clr-primary-200);
        color: var(--clr-neutral-900);
        font-weight: 600;
    }
}
