@import '~@logora/parliament.styles.theme/_theme.scss';

.scrutinContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1em;
    > p {
        margin: 0;
    }
}

.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
}

.uiBox {
    border: none !important;
    min-width: 300px;

    > div > span:first-of-type {
        color: #78909c;
    }

    svg {
        fill: #78909c;
    }
}

.titleBox {
    text-align: left;
    font-size: 24px;
}

.objetBox {
    display: flex;
    flex-direction: column;
    gap: 1em;
    a:hover {
        text-decoration: underline;
    }
}

.scrutinContent {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.votePosition {
    position: absolute;
    top: -17px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $font-size-normal;
    &.adopted {
        color: $success-color;
        background-color: $success-color-bg !important;
        border: 1px solid $success-color-border !important;
    }
    
    &.rejected {
        color: $cancel-color;
        background-color: $cancel-color-bg !important;
        border: 1px solid $cancel-color-border !important;
    }
    
    &.neutral {
        color: $neutral-color;
        background-color: $neutral-color-bg !important;
        border: 1px solid $neutral-color-border !important;
    }
}

.labelHemicycle {
    padding: 0.5em; 
    color: #434343 !important;
    display: flex; 
    flex-direction: column;
    gap: 1em;
    position: relative;
    border: 1px solid;
    border-radius: $box-border-radius;
    top: unset !important;
    padding: 1em;
    background-color: unset !important;
    &.adopted {
        color: $success-color;
        border: 1px solid $success-color-border !important;
    }
    
    &.rejected {
        color: $cancel-color;
        border: 1px solid $cancel-color-border !important;
    }
    
    &.neutral {
        color: $neutral-color;
        border: 1px solid $neutral-color-border !important;
    }
}

.scrutinMemberBox {
    padding-top: 1em;
}
