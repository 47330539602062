@import '~@logora/parliament.styles.theme/_theme.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.25em 1em;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10em;
}

.backLink {
    text-decoration: none;
    color: $text-secondary;
    cursor: pointer;
}