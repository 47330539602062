.milestone {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 0.5em;
    padding: 1em;
    gap: 1em;
    background-color: white;
    border: 2px solid transparent;

    &.nodeComplete:hover,
    &.nodeActive:hover {
        border: 2px solid #B0BEC5;
        background-color: #fafafa;
        border-radius: 8px;
        cursor: pointer;
    }
}

.selected {
    border: 2px solid #B0BEC5;
    background-color: #fafafa;
    border-radius: 8px;
    cursor: pointer;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75em;
    height: 70px;
    min-width: 200px;
}

.title {
    color: #111827;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin: 0;
    white-space: nowrap;
}

.subtitle {
    color: #374151;
    background-color: #f3f4f6;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
}

.nodeWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
    width: 2em;
    background-color: white;
}

.node {
    width: 1rem;
    height: 1rem;
    border: 2px solid black;
    border-radius: 25%;
    transform: rotate(45deg);
    transition: all 0.6s ease;
    z-index: 3;

    &:hover {
        transform: rotate(45deg) scale(1.1);
    }

    &:active {
        transform: rotate(45deg) scale(0.9);
    }

    &.nodeActive {
        border-color: black;
        background-color: white;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            background-color: black;
        }
    }

    &.nodeComplete {
        border-color: black;
        background-color: black;
    }
}

.button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    outline: none;
}

.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    height: 80px;
}

.subtext {
    color: #111827;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.8px;
}