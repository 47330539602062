@import '~@logora/parliament.styles.theme/_theme.scss';

.dashboard {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: $font-size-large;
}

.dossiersContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.dossiersWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 1em;
    gap: 1em;
}