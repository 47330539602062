.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
}

.title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1em;
}

.presentation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2em;
}

.box { 
    display: flex;
    flex: 1;
}

.corps {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 3em;
}

.boxContainer {
    flex-grow: 1;
    background-color: #f9f9f9 !important;
}

.empty {
    font-style: italic;
    text-align: center;
}

.link {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}