.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  gap: 2em;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.top, .bottom {
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: center;
}
