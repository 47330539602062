@import '~@logora/parliament.styles.theme/_theme.scss';

.container {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  align-items: center;
  max-height: 350px;
  overflow-y: auto;
  background-color: $background-color-secondary;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: $background-color-secondary;
  width: 100%;
  padding: 16px;
  margin-bottom: 0.5em;
  z-index: 1;
}

.title {
  text-transform: uppercase;
  color: #757575;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  position: relative;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background-color: #EEE;
  padding: 0.75em 0.75em;
  cursor: pointer;
}

.itemInfo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.date,
.itemObjet {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
}

.date {
  flex-grow: 4;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.itemObjet {
  flex-grow: 1;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.buttonLink {
  text-decoration: none;
}

.button {
  background-color: #EBEFF1;
  padding: 6px 8px;
  border: 1px solid #CFD8DD;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #F3F4F6;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
}

.empty {
  text-align: center;
}