.container {
    display: flex;
    flex-direction: column;
    margin: 1em 0;
}

.body {
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
}

.filter {
    display: flex;
}