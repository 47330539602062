.corps {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2em;
}

.boxContainer {
    flex-grow: 1;
}

.interventionTitle {
    font-size: 1.75rem;
    font-weight: 500;
}

.interventionSubtitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: #78909c;
}

.interventionTitle,
.interventionSubtitle {
    scroll-margin-top: 60px;
}

.container {
    display: flex;
    align-items: flex-start;
    gap: 1%;
}

.summary {
    margin-inline: 0;
    padding-inline: 0;
    min-width: 20rem;
    max-height: 80vh;
    overflow-y: auto;

    &,
    & ol {
        list-style: none;
    }

    & ol {
        margin-inline: 1em;
        padding-inline: 0;
    }

    margin-top: 2em;

    position: sticky;
    top: 100px;

    button {
        width: 100%;
        text-align: start;
        background: none;
        border: none;
        cursor: pointer;
    }

    &Title {
        color: #000;
        font-size: 1rem;
        font-weight: 500;
        margin: 0.75rem 0;
        line-height: 1.25rem;
    }

    &Subtitle {
        color: #78909c;
        font-size: 0.85rem;
        font-weight: 600;
        margin-block: 0.25rem;
    }

    &Subtitle:hover {
        color: #252f34;
    }
}
