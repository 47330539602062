@import '~@logora/parliament.styles.theme/_theme.scss';

.mainContainer {
    margin: 0 30px 0 0;
}

.uiBox {
    border: none !important;
    background-color: transparent;
    min-width: 300px;

    > div > span:first-of-type {
        color: $neutral-color-border;
    }

    svg {
        fill: $neutral-color-border;
    }
}

.svgContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 600px;
    height: 350px;
    flex-shrink: 0;
    position: relative;
}

.svgIcon {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.avatarBackground {
    background: linear-gradient(180deg, #0000 64%, #f9f9f9 0);
    padding: 1em;
    display: flex;
    gap: 2em;

    .nombreMandats {
        color: #777777;
        margin-top: 1.5em;
        font-weight: $font-weight-normal;
    }
}

.button {
    height: max-content;
    border: 1px solid $neutral-color-bg !important;
    padding: 0.5em !important;
    min-width: fit-content !important;
    height: 40px;
    color: $text-primary !important;

    svg {
        fill: $text-primary;
    }
}

.buttonRounded {
    border-radius: 50% !important;
}

.tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
    opacity: 0;
    transition: opacity 0.3s;
}

.tab {
    color: $call-primary;

    &.Mui-selected {
        color: $call-primary !important;
        font-weight: $font-weight-bold;
    }
}

.contactLine {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.linkHover:hover {
    cursor: pointer;
}
