@import '~@logora/parliament.styles.theme/_theme.scss';

.formContainer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.formTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1em;
    color: #333;
}

.formGroup {
    margin-bottom: 1em;
}

.switchLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
}

.textInput {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0000003b;
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    min-height: 56px;

    .textInputClass {
        border: none !important;
        height: 100%;
        &:focus {
            border: none !important;
        }
    }
}

.selectWrapper {
    [class*=Dropdown_dropdownWrapper] {
        width: 100% !important;
        [class*=Dropdown_dropdownHeader] {
            width: 100% !important;
            button {
                width: 100% !important;
            }
        }
        [class*=Dropdown_dropdownList] {
            width: 100% !important;
        }
    }
}

.selectInput {
    width: 100%;
    margin-bottom: 1em;
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0000003b;
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    min-height: 56px;

    &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    }
}

.checkboxGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1em;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    gap: 1em;
}

.submitButton {
    background: $neutral-color !important;
    border: 1px solid $neutral-color-border !important;
    color: white !important;
    padding: 10px 15px !important;
    font-size: $font-size-normal !important;
}

.cancelButton {
    background: $neutral-color-bg !important;
    border: 1px solid $neutral-color-border !important;
    color: $neutral-color !important;
    padding: 10px 15px !important;
    font-size: $font-size-normal !important;
}

.deleteButton {
    color: $cancel-color !important;
    padding: 10px 15px 10px 0 !important;
    font-size: $font-size-normal !important;
    margin-right: auto !important;
    border: none !important;
    &:hover {
        background-color: transparent !important;
    }
}

.alertOptions {
    color: $text-secondary;
    font-size: $font-size-small;
    margin-bottom: 1em;
}

.sourceButtons {
    display: flex;
    flex-wrap: wrap;
    gap: .75em;
    margin-bottom: 1em;
}

.sourceButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    user-select: none;
    border-radius: 6px;
    padding: 4px 12px;
    font-weight: normal;
    border: 1px solid $neutral-color-bg;
    color: $call-primary;
    background-color: $background-color-primary;
    font-size: $font-size-small;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.sourceButtonSelected {
    user-select: none;
    background-color: $neutral-color;
    border: 1px solid $neutral-color;
    color: #fff;
    font-weight: bold;
    svg {
        fill: #fff;
    }
}