.step {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.6rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: 1.5px solid #253238;
  border-radius: 4px;
  background-color: white;
  color: #253238;
  transition: transform 0.6s ease;

  &:hover {
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }

  &.stepPending {
    color: #B0BEC5;
    border: 1.5px solid #d1d5db;
    font-weight: 550;
    cursor: not-allowed;
  }

  .stepIcon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
}

.selected {
  background-color: black;
  color: white;
}
