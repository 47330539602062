.root {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    border: 1px solid #5B7FF1;
    border-radius: 6px;
    background-color: #5B7FF122 !important;
    color: #5B7FF1;
    align-items: center;
    padding: 25px;
    width: max-content;
}

.stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 200px;
    white-space: nowrap;
}

.statNumber {
    font-size: 35px;
    color: #5B7FF1;
    font-weight: bold;
}

.total {
    margin-bottom: 15px;
}

.moderationButton {
    padding: 15px;
    background-color: #5B7FF1 !important;
    color: #FFF !important;
    height: max-content;
    border-radius: 6px;
    text-decoration: none;
    display: flex;
    width: max-content;
    margin-top: 15px;
}

.arrowIcon {
    height: 22px;
    width: 22px;
    margin-left: 5px;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.resetFilters {
    align-self: center;
    margin-top: 15px;
    margin-right: 15px;
}