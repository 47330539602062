@import '~@logora/parliament.styles.theme/_theme.scss';

.searchFilter {
    display: inline-flex;
    align-items: center;
    padding: 0.25em 0.5em;
    background: $label-background-color;
    border-radius: $box-border-radius;
    font-size: $font-size-normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.searchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    border-radius: $box-border-radius;
    background-color: #f5f5f5;

    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }

    &.large {
        padding: 0.5em;
    }

    &.small {
        input {
            padding: 0;
        }
    }
}

.completionContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 5px;
}

.inputContainer {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.searchLabel {
    padding: 0.25em 0 !important;
}

.organeSearchLabel {
    padding-right: 0.25em !important;
}
