@import '~@logora/parliament.styles.theme/_theme.scss';

.navbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 1em 0 0 0;
    gap: 0.5em;
}

.navItem {
    box-shadow: none !important;
    text-transform: none !important;
    text-decoration: none !important;
    cursor: pointer;
    outline: 0;
}

button.button {
    background-color: white;

    padding-block: 0.5em;

    &:hover {
        color: #263238;
        background-color: white;
        box-shadow: none;
    }

    &.active {
        background-color: #37474f !important;
        color: white !important;

        &:hover {
            background-color: #263238 !important;
            color: white !important;
        }

        > span > svg {
            fill: white !important;
        }
    }

    > span > svg {
        font-size: 16px !important;
        fill: #37474f;
    }
}

button.tab {
    border: 1px solid transparent !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    display: inline-flex !important;
    padding-bottom: 0.25em !important;
    border: none !important;
    color: $text-secondary;

    > span > svg {
        font-size: 16px !important;
        fill: $text-secondary;
    }

    &:hover,
    &:focus {
        border-color: $text-primary;
        text-decoration: none;
    }

    &.active:hover,
    &:focus {
        background: transparent;
        border-width: 0 !important;
        border: none !important;
        color: $text-primary !important;
        background: transparent;
    }

    &.active {
        color: $text-primary;
        font-weight: bold;
        > span > svg {
            fill: $text-primary;
        }
        &::after {
            transform: scale(1);
        }
    }

    &::after {
        content: '';
        background-color: $text-primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3px;
        border-radius: $box-border-radius;
        transition: all 250ms ease 0s;
        transform: scale(0);
    }
}
