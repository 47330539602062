@import '~@logora/parliament.styles.theme/_theme.scss';

.title {
    font-size: 34px;
    font-weight: 500;
}

.subtitle {
    color: #777;
    font-size: 0.875rem;
    margin-top: auto;
}

.imageWrapper {
    width: 124px;
    height: 124px;
    background-color: #fff;
    border: solid 1px #eceff1;
    border-radius: 6px;
}

.image {
    width: 124px;
    height: 124px;
}

.avatarBackground {
    background: linear-gradient(to bottom, transparent 62%, #f9f9f9 35%);
    padding: 1em;
    display: flex;
    gap: 2em;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.presentation {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
}

.box {
    display: flex;
    flex: 1;
}

.membreOrgane {
    color: #434343;
    padding: 1em;
    cursor: pointer;
}

.corps {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.boxContainer {
    flex-grow: 1;
}

.tab {
    color: '#434343';
}

.uiBox {
    border: none;
    background-color: transparent;
    min-width: '300px';

    &>div>span:first-of-type {
        color: '#78909C';
    }

    & svg {
        fill: '#78909C'
    }
}