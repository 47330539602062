@import '~@logora/parliament.styles.theme/_theme.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  max-width: 60%;
  border: 1px solid #EEE;
  max-height: 1200px;
  overflow-y: auto;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    padding: 16px;

    .topTexte {
      text-transform: uppercase;
      color: #757575;
      font-weight: 600;
    }

    .summaryButton {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 14px;
      color: #202020;
      gap: 8px;
      background-color: #EBEFF1;
      border: none;
      cursor: pointer;
      z-index: 11;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #D1D5D8;
      }

      &:active {
        background-color: #B0B3B5;
      }
    }

    .menu {
      position: absolute;
      top: 8px;
      right: 16px;
      min-width: 140px;
      background-color: $background-color-secondary;
      border: 1px solid #eee;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 1em 1em;
      padding-top: 3em;
      z-index: 4;

      .menuItem {
        font-size: 14px;
        color: #546E7A;
        background-color: $background-color-secondary;
        display: block;
        text-decoration: none;
        color: inherit;
        border: none;
        width: 100%;
        cursor: pointer;
        text-align: start;

        &.article {
          font-size: 14px;
          padding: 2px 16px 2px 24px;
        }

        &.chapitre {
          font-size: 16px;
          padding: 2px 16px 2px 20px;
          font-weight: 800;
          color: #263238;
        }

        &.partie {
          font-size: 18px;
          padding: 12px 16px 2px 16px;
          font-weight: 800;
          color: #546E7A;
        }

        &.section {
          font-size: 14px;
          padding: 2px 16px 2px 24px;
          font-weight: 800;
          color: #263238;
        }

        &.sous-section {
          font-size: 14px;
          padding: 2px 16px 2px 24px;
          font-weight: 800;
          color: #263238;
          font-style: italic;
        }

        &.titre {
          font-size: 16px;
          padding: 6px 16px 2px 16px;
          font-weight: 800;
          color: #263238;
        }

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: relative;
  }

  .formule {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
  }

  .articleTitle {
    font-size: 1.3rem;
    text-align: center;
    margin: 0;
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

    .amendmentButton {
      padding: 2px 8px;
      opacity: 0.5;
      border: 1px solid #EBEFF1;
      border-radius: 4px;
      font-size: 14px;
      color: #253238;
      background-color: transparent;

      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;

      &[class*="active"] {
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: #FAFAFA;
        }
      }
    }

    .summaryButton {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 14px;
      color: #202020;
      gap: 8px;
      background-color: #EBEFF1;
      border: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #D1D5D8;
      }

      &:active {
        background-color: #B0B3B5;
      }
    }
  }

  .subdivisions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    padding: 16px;
    scroll-margin-top: 64px;
    scroll-snap-align: start;

    .subdivision {
      scroll-margin-top: 36px;
      scroll-snap-align: start;
      margin: 1em 0em;
    }
  }

  .alineas {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 32px;
  }
}

.empty {
  text-align: center;
}