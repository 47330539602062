.root {
    display: flex;
}

.content {
    padding: 15px;
    margin: 15px 0 20px 0;
    display: flex;
    flex-direction: column;
    max-width: 800px;
}

.user {
    margin-bottom: 15px;
    display: block;
}

.contentHeader {
    display: flex;
    flex-direction: row;
}

.position {
    align-self: center;
    display: block;
    margin-bottom: 15px;
    background-color: #5B7FF122 !important;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    color: #5B7FF1;
}

.subtitle {
    padding: 15px 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75em;
}

.moderableContent {
    margin-bottom: 15px;
}

.footer {
    color: #999;
    display: flex;
    flex-direction: row;
    width: max-content;
    background-color: #e7e7e7;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    font-size: 14px;

    span {
        margin-left: 5px;
    }
}

.sourcesContainer {
    background-color: #e8e8e8;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    color: #222 !important;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: flex-start;
    max-width: max-content;
    outline: none !important;
    padding: 5px;
    margin-top: 5px;
    text-decoration: none !important;
}

.originLink {
    display: flex;
    text-decoration: none;
    color: rgb(91, 127, 241);
    padding-right: 5px;
    align-self: center;
}

.originAbsoluteLink {
    font-size: 12px;
    text-decoration: none;
    color: rgb(91, 127, 241);
    cursor: pointer;
}

.entryHeader {
    display: flex;
    flex-direction: row;
}

.statusHeader {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.entryType {
    align-self: center;
    display: block;
    background-color: rgba(0, 0, 0, 0.08) !important;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 15px;
}

.status {
    align-self: center;
    display: block;
    background-color: rgba(0, 0, 0, 0.08) !important;
    padding: 5px;
    border-radius: 6px;
    color: #999;
    font-size: 14px;
}

.entryStats {
    display: flex;
    flex-direction: row;
}

.entryStatItem {
    display: flex;
    flex-direction: row;
    border: 1px solid #5B7FF1;
    border-radius: 6px;
    padding: 0.5em 0.75em;
    color: #5B7FF1;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    white-space: nowrap;

    svg {
        padding-left: 5px;
    }
}

.reportItem {
    display: flex;
    flex-direction: row;
    border: 1px solid #FF000022;
    background: #FF000022;
    border-radius: 6px;
    padding: 0.5em 0.75em;
    color: #FF0000;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;

    svg {
        padding-left: 5px;
    }
}

.showParent {
    display: flex;
    flex-direction: row;
    padding: 0.5em 0.75em;
    color: #5B7FF1;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}

.createdAt {
    margin-left: auto;
    align-self: center;
    font-size: 12px;
    color: #666;
    margin-bottom: 15px;
    margin-right: 15px;
}

.drawerTitle {
    display: block;
    width: max-content;
    background-color: rgba(0, 0, 0, 0.08) !important;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    margin: 15px 15px 0 15px;
}

.moderableTitle {
    margin-bottom: 10px;
}

.pointIcon {
    height: 25px;
    width: 25px;

    > path {
        fill: #5B7FF1;
    }

    > circle {
        stroke: #5B7FF1;
    }
}

.reformulateBtn {
    margin-left: auto;
    margin-right: 0;
}

.translatedTitle {
    margin-bottom: 5px;
}