.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  width: 100%;
  position: relative;
  padding: 16px;
  background-color: #FAFAFA;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .title {
    text-transform: uppercase;
    color: #757575;
    font-weight: 600;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;

    div[class*="AmendmentBox_amendmentBoxContainer"] {
      background-color: #FAFAFA;
      border-bottom: 2px solid #CFD8DD;
    }

    div[class*="MuiFormControl-root"][class*="MuiTextField-root"] {
      min-width: 120px;
    }

    div[class*="MuiPaper-root"][class*="MuiPaper-elevation"] {
      box-shadow: none;
      background-color: #FAFAFA;

      tfoot td {
        border-bottom: none;
      }
    }
  }
}