.container {
    padding: 0 1em;
}

.header {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1em;
}

.label {
    font-size: 12px;
    font-weight: 550;
    letter-spacing: 0.8px;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 1em;
    letter-spacing: 0.25px;
}

.presentation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2em;
}

.box {
    display: flex;
    flex: 1;
}

.boxContainer {
    flex-grow: 2;
    background-color: #FAFAFA;
    padding: 8px;

    .boxHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
            color: #757575;
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .icon {
            color: #BDBDBD;
            font-size: 18px;
        }
    }
    
    .boxBody {
        color: #202020;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 2px;
    }

    .link {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.commissionBox {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.navigationItem {
    flex: 1;
}

.navigationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2em;

    .stepButton {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 550;

        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: #546E7A;

        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: #EBEFF1;
        }

        &:active {
            box-shadow: 0 0 0 1px #000;
        }

        &:disabled {
            opacity: 0.3;
        }
    }
    
    .nextButton {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.stepTitle {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.listNavigation {
    background-color: #FFF;
}

.tabs {
    color: #666;
    font-size: 14px;
    font-weight: 550;
}

.tab {
    position: relative;
}

.timelineWrapper {
    margin-top: 2em;
    margin-bottom: 4em;
}
