
.container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0em 1em;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
}

.title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1em;
}

.presentation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2em;
}

.box { 
    display: flex;
    flex: 1;
}

.corps {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2em;
    margin-top: 1em;
}

.boxContainer {
    flex-grow: 1;
    background-color: #f9f9f9 !important;
}

.link {
    cursor: pointer;
    
    &:hover {
        text-decoration: underline !important;
    }
}