.timelineContainer {
  position: relative;
}

.timelineContainer:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: black;
  top: 47.5%;
  left: 0;
  z-index: 2;
}

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  max-width: 80vw;
  overflow-x: scroll;
  margin: 0 auto;
  padding: 3em 20px;
}

.legislatureContainer {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.legislatureBanner {
  position: absolute;
  width: 98%;
  background-color: #EEEEEE;
  color: #615f5f;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  padding: 4px 0;
  top: -2.5em;
  height: 1.8em;
  z-index: 100;
  text-align: center;
}

.milestone {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}