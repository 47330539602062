.header {
    margin-bottom: 40px;
}

.container {
    display: grid;
    grid-template-columns: minmax(450px, 1fr) minmax(400px, 2fr);
    gap: 30px;
    padding-right: 30px;
}
