@import '~@logora/parliament.styles.theme/_theme.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0em 1em;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
}

.title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1em;
}

.presentation {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
}

.left {
    display: flex;
    flex-flow: column wrap;
    gap: 1em;
}

.tabContainer {
    width: 100%;
}

.box { 
    display: flex;
}

.corps {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2em;
    margin-top: 2em;
}

.boxContainer {
    flex-grow: 1;
}

.labelHemicycle {
    padding: 0.5em; 
    color: #434343 !important;
    display: flex; 
    flex-direction: column;
}