@import '~@logora/parliament.styles.theme/_theme.scss';

.container {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  align-items: center;
  max-height: 350px;
  max-width: 60%;
  overflow-y: auto;
  background-color: $background-color-secondary;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $background-color-secondary;
  padding: 16px;
  margin-bottom: 0.5em;
  z-index: 1;

  .title {
    text-transform: uppercase;
    color: #757575;
    font-weight: 600;
  }
}

.originalTextButton {
  padding: 2px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  line-height: 14px;
  border: 1px solid #202020;
  border-radius: 4px;
  display: flex;
  gap: 8px;
}

.content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  position: relative;

  .text {
    overflow-y: auto;
    white-space: pre-wrap;
  }
}