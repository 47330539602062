@import '~@logora/parliament.styles.theme/_theme.scss';

.listWrapper {
    padding-right: 1em;
    width: 100%;
}

.keywords {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
}

.mainButton {
    background: $neutral-color-bg !important;
    border: 1px solid $neutral-color-border !important;
    color: $neutral-color !important;
    padding: 10px 15px !important;
    font-size: $font-size-normal !important;
}

.editButton {
    color: $neutral-color !important;
    padding: 10px 15px !important;
    font-size: $font-size-normal !important;
    span {
        margin: 0 !important;
        svg {
            font-size: 32px !important;
        }
    }
}

.alertIcon {
    fill: #455A64;
    margin-bottom: 1.5em;
}