.platformIcon {
    max-width: 40px;
}

.platformName {
    text-transform: capitalize;
    font-weight: 600;
    margin-block: 0.5em;
}

.platformDescription {
    color: var(--clr-neutral-100);
}

.connectBtn {
    margin-top: 20px;
    position: relative;

    a {
        display: grid;
        place-items: center;
        position: absolute;
        inset: 0;
    }
}
