@import '~@logora/parliament.styles.theme/_theme.scss';

.tabsContainer {
    display: flex;
    flex-direction: column;
}

.tabsList {
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 4px;
    margin-bottom: 16px;
}

.tab {
    padding: 8px 16px;
    font-size: 14px;
    color: $call-primary;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;
    user-select: none;
    &.active {
        font-weight: $font-weight-bold;
        outline: none;
        border-bottom: 2px solid #434343;
    }
}

.tabPanel {
    width: 100%;
    border-radius: 4px;
}